.MediaPanelContainer {
  position: fixed;
  opacity: 0;
  user-select: none;  // This prevent iOS from selecting content on "long press"

  background-color: #000000;

  transition-property: top, left, right, bottom, transform, opacity;
  transition-duration: 350ms;
  transition-timing-function: ease-in-out;

  .dragHandle {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(38, 38, 38, 0.5);
    border: 0px solid #000;
    user-select: none;  // This prevent iOS from selecting content on "long press"
    touch-action: none; // We are going to use the more universal "Pointer Events" not touch events

    transition: opacity 500ms ease-in-out;

    // The after pseudo element will create the "drag handle dots"
    &:after {
      content:"";
      display: block;
      background-image: url('data:image/svg+xml,<svg width="224" height="224" version="1.1" viewBox="0 0 4 4" xmlns="http://www.w3.org/2000/svg"><g fill="%234E4E4E"><circle cx="1" cy="1" r=".6"/><circle cx="1" cy="3" r=".6"/><circle cx="3" cy="1" r=".6"/><circle cx="3" cy="3" r=".6"/></g></svg>');
      background-position: center;
    }

    &.dragHandleHidden {
      opacity: 0;
      pointer-events: none;
    }

    &:hover {
      border-color: #4E4E4E;
    }

    &.dragHandleActive {
      border-color: #4E4E4E;
      background: #4E4E4E;
    }
  }

  &.MediaActive {
    opacity: 1;
  }

  .iOS-bottom-filler {
    width: 100%;
    background-color: #000;
    display: none;
  }

  &.MediaPanelPosition-top {
    top: 0;

    // transform: translateY(-100%);
    // &.MediaActive {
    //   transform: translateY(0);
    // }

    .dragHandle {
      border-width: 0 0 2px 0;
      bottom: 0;
    }

    //
    // When Safari is full screen on certain iPads (that have the drag handle line at the top
    // center of the screen), Safari will offset the top of the viewport by 25px so that UI
    // doesn't get lost behind the iOS drag handle.
    //
    // When the MediaPanel is open in the top position it was leaving a 25px gap through which
    // to see prompter content behind/below the media panel. We want to fill this gap for visual
    // continuity.
    //
    .iOS-bottom-filler {
      display: block;
      position: absolute;
      bottom: 100%;
      height: 25px;
    }

    &.FlipVertical {
      .dragHandle {
        border-width: 2px 0 0 0;
        top: 0;
        bottom: auto;
      }

      .iOS-bottom-filler {
        top: auto;
        top: 100%;
        height: 25px;
      }
    }
  }

  &.MediaPanelPosition-bottom {
    bottom: 0;

    // transform: translateY(100%);
    // &.MediaActive {
    //   transform: translateY(0);
    // }

    .dragHandle {
      border-width: 2px 0 0 0;
      top: 0;
    }

    .video-panel-toolbar {
      top: 24px;
    }

    //
    // When Safari is full screen on certain iPads (that have the drag handle line at the bottom
    // center of the screen), Safari will offset the bottom of the viewport by 20px so that UI
    // doesn't get lost behind the iOS drag handle.
    //
    // When the MediaPanel is open in the bottom position it was leaving a 20px gap through which
    // to see prompter content behind/below the media panel. We want to fill this gap for visual
    // continuity.
    //
    .iOS-bottom-filler {
      display: block;
      position: absolute;
      top: 100%;
      height: 20px;
    }

    &.FlipVertical {
      .dragHandle {
        border-width: 0 0 2px 0;
        top: auto;
        bottom: 0;
      }

      .iOS-bottom-filler {
        top: auto;
        bottom: 100%;
        height: 20px;
      }
    }
  }

  &.MediaPanelPosition-left {
    left: 0;

    // transform: translateX(-100%);
    // &.MediaActive {
    //   transform: translateX(0);
    // }

    .dragHandle {
      border-width: 0 2px 0 0;
      right: 0;
    }

    .video-panel-toolbar {
      right: 24px;
    }

    &.FlipHorizontal {
      .dragHandle {
        border-width: 0 0 0 2px;
        left: 0;
        right: auto;
      }
    }
  }

  &.MediaPanelPosition-right {
    right: 0;

    // transform: translateX(100%);
    // &.MediaActive {
    //   transform: translateX(0);
    // }

    .dragHandle {
      border-width: 0 0 0 2px;
      left: 0;
    }

    &.FlipHorizontal {
      .dragHandle {
        border-width: 0 2px 0 0;
        left: auto;
        right: 0;
      }
    }
  }

  &.MediaPanelPosition-top,
  &.MediaPanelPosition-bottom {
    // Horizontal Media Panel (Top/Bottom)
    .dragHandle {
      height: 16px;
      cursor: ns-resize;

      &:after {
        width: 32px;
        height: 12px;
        margin: 0 auto;
        background-repeat: repeat-x;
        background-size: 25% 100%;
      }
    }
  }

  &.MediaPanelPosition-left,
  &.MediaPanelPosition-right {
    // Vertical Media Panel (Left/Right)
    .dragHandle {
      width: 16px;
      cursor: ew-resize;

      &:after {
        position: relative;
        width: 8px;
        height: 32px;
        margin: 0 3px;
        background-repeat: repeat-y;
        background-size: 100% 25%;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .video-panel-toolbar {
      top: 0;
      height: 64px;
    }
  }

  &.MediaPanelPosition-top,
  &.MediaPanelPosition-bottom,
  &.MediaPanelPosition-left,
  &.MediaPanelPosition-right {
    padding: 16px;
    z-index: var(--zindex-mediapanel-above-content);

    video {
      border-radius: 6px;
    }

    .dragHandle {
      display: block;
    }
  }

  &.MediaPanelPosition-background {    // Darken the video feed
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    video {
      filter: brightness(50%);
    }

    // Don't show MediaPanel Video toolbars when media panel is in background mode.
    .video-actions-toolbar {
      display: none;
    }
  }

  canvas {
    visibility: hidden;

    position: absolute;
    left: 100%;
  }

  .CountdownContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;

    display: table;

    .CountdownNumber {
      display:table-cell;
      vertical-align:middle;
      text-align: center;

      color: rgba(255, 255, 255, 0.3);
      font-size: 85vh;
    }

    .CountdownNumber.pulse-animation {
      animation: timer_beat 1s;
    }

    &.CountdownFlash {
      background: #fff;
      opacity: 0;
      animation: camera_flash 1s;
    }
  }
}

//
// When the prompter content (script) is hidden, and the MediaPanel is configured in background
// mode, then return the video to full brightness (video is dimmed when used as a background to
// the regular prompter script content).
//
.PrompterBlanking {
  .MediaPanelContainer {
    &.MediaPanelPosition-background {
      video {
        filter: brightness(100%);
      }
    }
  }
}

.VideoPanel {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  z-index: 1;

  overflow: hidden; // The video toolbar will slide out the bottom of the VideoPanel when hidden.

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: filter 500ms ease-in-out;
  }
}

@keyframes timer_beat {
  0%, 40% { transform: none; }
  15% { transform: scale(1.125); }
}

@keyframes camera_flash {
  0%, 70% { opacity: 0; }
  30% { opacity: 1; }
}

// The recording-badge is the floating rectangle in the upper left corner of a video panel when the
// video is being recorded.
.recording-badge {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 8px;
  background: rgba(0,0,0,0.6);
  border-bottom-right-radius: 6px;
  user-select: none;
}

// The record-indicator is the small red circle at the left hand side of the record-badge component
// which resembles a red LED indicator.
.recording-indicator {
  position: relative;
  width: 8px;
  height: 8px;
  background: #ff0000;
  border-radius: 50%;
  // box-shadow: 0 0 0 2px #fff;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: indicatorPulse 1.5s infinite ease-in-out;
    // border: 1px solid currentColor;
    border: 1px solid #fff;
    content: "";
  }

  @keyframes indicatorPulse {
    0% {
      transform: scale(1.0);
      opacity: 0.6;
    }
    66% {
      transform: scale(2.4);
      opacity: 0;
    }
    100% {
      transform: scale(1.0);
      opacity: 0;
    }
  }
}

.video-panel-toolbar {
  position: absolute;
  top: 6px;
  right: 6px;
  display: flex;
  align-items: center;
  // justify-content: center;

  transition: opacity 500ms ease-in-out;

  &.hideToolbar {
    opacity: 0;
    pointer-events: none;
  }
}

.video-bottom-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  // transform: translateY(64px);
  transform: translateY(0);
  transition: transform 500ms ease-in-out;

  &.hideToolbar {
    transform: translateY(64px);
    pointer-events: none;
  }
}

.video-label-bar {
  padding: 2px 6px;

  background: rgba(0,0,0,0.4);
  color: rgba(255,255,255,0.8);
  border-top-right-radius: 6px;

  user-select: none;
}

.video-bottom-toolbar {
  width: 100%;
  height: 64px;
  padding: 0 6px;
  background: rgba(0,0,0,0.6);
  border-top: 1px solid rgba(255,255,255,0.2);;
}

.video-actions-toolbar {
  position: absolute;
  left: 0; right: 0;

  transition: opacity 500ms ease-in-out;

  &.hideToolbar {
    opacity: 0;
    pointer-events: none;
  }
}
