
.PrompterHudRight {
  position: absolute;
  top:0;bottom:0;
  right:0;
  width: 45vw;
  padding: 0px 8px 0px 0px;

  /*background: #ff0000;*/
  color: #fff;

  z-index: var(--zindex-prompter-hud-right);
  pointer-events: none;

  p {
    text-align: right;
  }
}

.PrompterHudRight.hideMode {
  transform: translateX(100%);
}

// This md breakpoint in MUI is 900px
@media (min-width: 600px) {
  .PrompterHudRight {
    width: 25vw;
  }
}

// This md breakpoint in MUI is 900px
@media (min-width: 900px) {
  .PrompterHudRight {
    width: 22vw;
  }
}

@media (min-width: 1025px) {
  .PrompterHudRight {
    width: 18vw;
    padding: 8px 16px 8px 8px;
  }
}