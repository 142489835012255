.SegmentElement {
  position: relative;

  .SegmentElementBorder {
    border-radius: 8px;
    &.isSelected {
      outline: 2px dashed #ccc;
    }
  }

  .SegmentElementHeader {
    border-radius: 8px 8px 0 0;
    background-color: rgba(38, 38, 38, 0.5); // #131313;
    color: #4E4E4E;
    display: flex;
    user-select: none;

    .SegmentNumber {
      background: rgba(0,0,0,0.25); // #000;
      min-width: 2rem;
      height: 2rem;
      margin: auto 0 auto 0.5rem;
      font-size: 2rem;
      text-align: center;
      line-height: 1;
      border-radius: 1rem;
    }

    h1 {
      margin: auto 0.5em;
      flex-grow: 1;
      font-size: 2rem;
      text-align: left;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .SegmentElementBody {
    position: relative;
    padding: 0.5em 1em 0.25em 1em;
    border-left: 4px solid rgba(38, 38, 38, 0.5); // #131313;
    border-right: 4px solid rgba(38, 38, 38, 0.5); // #131313;
    outline: 0 none;

    .kbdshortcut {
      display: inline-flex;
      vertical-align: text-bottom;
      align-items: center;
      color: #eee;
      font-size: 0.5em;
    }
    kbd {
      position: relative;
      display: inline-block;
      min-width: 2em;
      min-height: 2em;
      margin: 0px 4px;
      padding: 8px 22px 8px 15px;
      border-radius: 10px;
      color: #fff;
      background: linear-gradient(180deg, #282828, #202020);
      border: 1px solid #282828;
      box-shadow: inset -8px 0 8px rgba(0,0,0,0.15),
      inset 0 -8px 8px rgba(0,0,0,0.25),
      0 0 0 2px rgba(0,0,0,0.75), 10px 20px 25px rgba(0,0,0,0.4);
      overflow: hidden;
      line-height: 1em;
      font-size: 1em;
      text-transform: uppercase;
      z-index:0;
    }
    kbd::before {
      content: '';
      position: absolute;
      top: 3px;
      left: 4px;
      bottom: 14px;
      right: 12px;
      background: linear-gradient(90deg, #232323, #4a4a4a);
      border-radius: 10px;
      box-shadow: -10px -10px 10px rgba(255,255,255,0.25), 10px 5px 10px rgba(0,0,0,0.15);
      border-left: 1px solid #0004;
      border-bottom: 1px solid #0004;
      border-top: 1px solid #0009;
      z-index:-1
    }
  }

  .SegmentElementFooter {
    user-select: none;

    display: flex;
    flex-direction: column;

    border-style: solid;
    border-color: rgba(38, 38, 38, 0.5); // #131313;
    border-width: 0 4px 4px 4px;
    border-radius: 0 0 8px 8px;
    /*background-color: #131313;*/
    color: #4E4E4E;
    display: flex;
    /*min-height: 0.5em;*/
    /*padding: 0 0 0 0.25em;*/
    /*font-size: 2rem;*/

    .SegmentNumber {
      background: #000;
      min-width: 2rem;
      height: 2rem;
      margin: auto 0 auto 0.5rem;
      font-size: 2rem;
      text-align: center;
      line-height: 1;
      border-radius: 1rem;
    }

    h1 {
      margin: 0 0.5em;
      flex-grow: 1;
      font-size: 2rem;
      text-align: left;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
    }

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    span {
      font-size: 2rem;
      line-height: 1.5;
      /*margin: 0 0.5em;*/
    }
  }

  p {
    margin: 0;
    position: relative;
  }
}

.ShotLogActionsContainer {
  border-radius: 0 0 8px 8px;

	&.isPaused {
    z-index: 1100;
	}
}
.ShotLogActionsHeader {
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  background: rgba(38,38,38,.5);  //#131313;
  padding: 0.25em 0;
  color: #4E4E4E;
  margin-bottom: 2px;
}
.ShotLogActionsInline {
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(38,38,38,.5);  //#131313;
  color: #4E4E4E;
  padding: 6px 2px 2px 2px;
  border-radius: 0 0 4px 4px; // Default is for portrait mobile viewport (xs)

  font-size: 1rem;

  /*
	&.isPaused {
		// color: #4297d7;
		// border: 2px solid #4297d7;
		// box-shadow: 0 0 12px #4297d7;
    z-index: 1100;
	} */

  .ShotLogButton {
    flex-direction: column;
    flex: 1;
    border-radius: 8px;

    .MuiButton-startIcon {
      margin-right: 0;

      .MuiSvgIcon-root {
        font-size: 1em;
      }
    }
    .MuiButton-endIcon {
      margin-left: 0;

      .MuiSvgIcon-root {
        font-size: 1em;
      }
    }

    .ShotLogButtonProgress {
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      opacity: 0.3;
    }
    .ShotLogButtonLabel {
      padding: 6px 8px 0 8px;
      font-size: 1em;
    }
  }

  .ShotLogButton.FlexReverse {
    flex-direction: column-reverse;
  }
}

//
// .MaskWordLimit will be applied to .SegmentElementBody div if we currently have word limit
// enabled (free user).
//
.MaskWordLimit {

  // .MaskedElement will be applied to any rendered parapgraph where the document word count at
  // the end of that paragraph is greater than or equal to our word limit threshold.
  .MaskedElement {
    // Some browsers like firefox won't allow a blur filter with unit of measure expressed as a
    // fraction of line height (so it scales with screen resolution). This is our backup CSS for
    // those browser which will hopefully be replaced on the next line.
    filter: brightness(80%) blur(10px);

    // Firefox does not parse blur filters with a `lh` unit of measure - so it will use the last
    // known good filter value, the line above.
    filter: brightness(80%) blur(0.1lh);
  }

}

// This is the SM breakpoint in MUI
@media (min-width: 600px) {
  .SegmentElement {
    border-radius: 16px;

    .SegmentElementHeader {
      border-radius: 16px 16px 0 0;
    }
    .SegmentElementFooter {
      border-radius: 0 0 16px 16px;
    }
  }
  .ShotLogActionsHeader {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .ShotLogActionsInline {
    border-radius: 0 0 12px 12px; // For viewports larger than XS
    font-size: 1.3rem;
  }
}

// Media query for higher resolution (probably 4k, but sometime laptops have odd resoutions) displays
@media only screen and (min-width: 2000px) {

  .SegmentElement {
    border-width: 4px;
  }

}