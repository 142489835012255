.EndElementOffScriptContainer {
  position: absolute;
  left: 0;
  pointer-events: none;
  container-type: inline-size;

  .EndElementOffScript {
    margin: 0 1em;
    position: sticky;
    top: 0;
    left: 0;

    color: #4E4E4E;
    border-radius: 8px;
    transition: background-color 250ms linear, color 250ms linear, outline 250ms linear, border 100ms linear, box-shadow 100ms linear, margin 500ms ease-in-out;

    text-transform: uppercase;
    user-select: none;

    display: flex;
    flex-direction: column;
    justify-content: center; /* align horizontal */

    user-select: none;
    pointer-events: auto;
    overflow: hidden;

    h1 {
      background: rgba(38, 38, 38, 0.5);
      width: 100%;
      margin:0;padding:10px 25px;
      font-size: max(18px, 0.5em);
      text-align: center;
      border-radius: 8px 8px 0 0;
    }
  }

  &.isEndOfScript {
    z-index: var(--zindex-end-element);

    .EndElementOffScript {
      background: rgba(28, 28, 28, 0.75);
      color: #4297d7;
      outline: 2px solid #4297d7;
      box-shadow: 0 0 12px #4297d7;
    }
  }
}

.FlipHorizontal {
  .EndElementOffScript {
    transform: rotateY(180deg);
  }
}
.FlipVertical {
  .EndElementOffScript {
    top: unset;
    bottom: 0;
    transform: rotateX(180deg);
  }
}
.FlipHorizontal.FlipVertical {
  .EndElementOffScript {
    transform: rotateY(180deg) rotateX(180deg);
  }
}