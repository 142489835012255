/* Add this class to the body element to hide scrollbars. */
.invisible-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */

  &::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
  }
}

.HideCursor {
  cursor: none;

  // Let's make sure nothing inside .HideCursor has a cursor, even if some components have CSS
  // cursor values.
  * {
    cursor: none !important;
  }
}

.PrompterContainer {
  position: relative;

  /* Testing LINEAR GRADIENT WITH TRANSPARENCY
  &::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 1%, rgba(0, 0, 0, 1) 10%, rgba(1, 1, 1, 0) 99%); /-* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *-/
    // background-clip: text;
    // background-color: #ffff00;

    // mask-image: linear-gradient(black, transparent);
    // mask-repeat: no-repeat;
  }
  */

  /*
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background: #00ff00;
  */

  /*&:after {
    /-* Tally Element places a border around the viewport *-/
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 3px solid #ff0000;
    z-index: 1500;
    pointer-events: none;
  }*/

  /**
  * Indicator when a file is actively being dragged over the PrompterViewport.
  */
  &.DragActive {
    &:after {
      content:"";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;

      background: rgba(0,0,0,0.75);
      background-image: url('data:image/svg+xml,<svg width="800" height="800" viewBox="-20 0 190 190" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m91.783 138.671 10.083 22.08-10.083 5.52-10.083-23-14.666 11.96 2.75-57.96 22.677 22.244 4.247-29.292-17.521-1.88.927-17.686-26.111-2.88-8.487 56.96 17.018 2.65-.399 5.604-22.689-3.28 9.542-68.167 37.34 4.312 16.691 19.803-5.449 38.868 12.545 12.305-18.332 1.839z" fill="%23fff"/></svg>');
      background-size: 30vh;
      background-repeat: no-repeat;
      background-position: center;

      outline: 3px dashed #fff;
      outline-offset: -3vh;

      z-index: var(--zindex-promptercontainer-dragactive);
    }
  }

  &.MaskHidden {
    > .PrompterMaskFocus {
      display:none;
    }
  }

  // .PrompterContainer.MaskFadeIn
  &.MaskFadeIn {
    > .PrompterMaskTop {
      transform: translateY(100%);
      animation: slideInTop 500ms ease-in-out; //ease-in-out
    }
    > .PrompterMaskFocus {
      opacity:1;
      animation: slideInLeft  500ms ease-in-out;
    }
    .PrompterMaskArrowLeft, .PrompterMaskArrowRight {
      opacity:1;
      animation: slideInLeft  500ms ease-in-out;
    }
    > .PrompterMaskBottom {
      transform: translateY(-100%);
      animation: slideInBottom 500ms ease-in-out;  //ease-in-out
    }
  }

  // .PrompterContainer.MaskVisible
  &.MaskVisible {
    > .PrompterMaskTop {
      transform: translateY(100%);
    }
    > .PrompterMaskFocus {
      opacity:1;
    }
    .PrompterMaskArrowLeft, .PrompterMaskArrowRight {
      opacity:1;
    }
    > .PrompterMaskBottom {
      transform: translateY(-100%);
    }
  }

  // .PrompterContainer.MaskFadeOut
  &.MaskFadeOut {
    > .PrompterMaskTop {
      animation: slideOutTop 500ms ease-in-out;  //ease-in-out
    }
    > .PrompterMaskFocus {
      opacity:0;
      animation: slideOutLeft  500ms ease-in-out;
    }
    .PrompterMaskArrowLeft, .PrompterMaskArrowRight {
      opacity:0;
      animation: slideOutLeft  500ms ease-in-out;
    }
    > .PrompterMaskBottom {
      animation: slideOutBottom 500ms ease-in-out;  //ease-in-out
    }
  }
}

.PrompterBlanking {
  .PrompterContent {
    visibility: hidden;
  }
  .PrompterMaskFocus {
    visibility: hidden;
  }
}

@keyframes slideInTop {
  0% { transform: translateY(64px); }
  100% { transform: translateY(100%); }
}
@keyframes slideOutTop {
  0% { transform: translateY(100%); }
  100% { transform: translateY(64px); }
}

@keyframes slideInLeft {
  0% { opacity:0; }
  100% { opacity:1; }
}
@keyframes slideOutLeft {
  0% { opacity:1; }
  100% { opacity:0; }
}

@keyframes slideInBottom {
  0% { transform: translateY(-64px); }
  100% { transform: translateY(-100%); }
}
@keyframes slideOutBottom {
  0% { transform: translateY(-100%); }
  100% { transform: translateY(-64px); }
}

.PrompterMaskFocus {
  position: fixed;
  left: 0;
  right: 0;
  /*margin: 0 auto; THIS IS NOW SET IN JS BASED ON LEFT GUTTER */
  /*top: 30%;
  bottom: 30%;*/
  /*border: 1px solid rgba(66, 151, 215, .4);*/
  /*opacity:0;*/
  transition: opacity 500ms;
  pointer-events: none; // Don't listen to any mouse clicks or touch events.

  .PrompterMaskArrowLeft, .PrompterMaskArrowRight {
    color: #4A4A4A;
    pointer-events: all;
    cursor: ns-resize;
    line-height: 0;     // Make sure the surrounding div that contains the SVG doesn't have any phantom padding below SVG
    user-select: none;  // This prevent iOS from selecting content on "long press"
    touch-action: none; // We are going to use the more universal "Pointer Events" not touch events
  }

  &.dragCueActive {
    .PrompterMaskArrowLeft, .PrompterMaskArrowRight {
      color: #4297d7;
    }
  }
}
.PrompterPlaying, .PrompterPaused {
  .PrompterMaskArrowLeft, .PrompterMaskArrowRight {
    color: #4297d7;
    pointer-events: unset;
    cursor: unset;
  }
}

.PrompterMaskArrowLeft, .PrompterMaskArrowRight {
  /*
  opacity:0;
  transition: opacity 1s;
  */
  position: absolute;
  top: 50%;
  /*transform: translate(0, -50%);*/
  color: #4297d7;
  z-index: var(--zindex-prompter-cue-indicator);
}
.PrompterMaskArrowLeft {
  left: 0;
  transform: translate(0, -50%);
}
.PrompterMaskArrowRight {
  right: 0;
  transform: scaleX(-1) translate(0, -50%);
}
.PrompterMaskTop {
  position: fixed;
  left:0;
  bottom:100%;
  width:100%;
  /*height: 30%;*/
  background: rgba(0,0,0,0.75);
  /*background: rgba(255, 0,0,0.6);*/
  /*transform: translateY(64px);*/
  z-index: var(--zindex-prompter-mask);
  transition: height 500ms ease-in-out, transform 500ms ease-in-out;
  pointer-events: none; // Don't listen to any mouse clicks.
}
.PrompterMaskBottom {
  position: fixed;
  top: 100%;
  left:0;
  /*transform: translateY(-64px);*/
  width:100%;
  /*height: 30%;*/
  background: rgba(0,0,0,0.75);
  /*background: rgba(255, 0,0,0.6);*/
  z-index: var(--zindex-prompter-mask);
  transition: height 500ms ease-in-out, transform 500ms ease-in-out;
  pointer-events: none; // Don't listen to any mouse clicks.
}
