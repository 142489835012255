.PrompterHudTocContainer {
  width: 100%;
  max-height: 40vh;
  overflow-y: scroll;
  touch-action: pan-y;
}
.PrompterHudToc {
  width: 100%;
  /*max-width: 360px;*/
  color: #eee;
  // opacity: prompterState.isPlaying ? 0 : 1;
  transition: opacity 1s linear;

  .MuiListSubheader-root {
    color: #eee;
    background: none transparent;
  }

  .MuiListItemIcon-root {
    min-width: 30px;

    .MuiSvgIcon-root {
      color: #ff0000;
    }
  }

  /*.MuiListItemText-root {

  }*/
}