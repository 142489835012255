/*
 * This file intends to capture only those CSS properties that would be customizeable in a
 * prompter "theme". This will mostly include background color and foreground color.
 */
// .PrompterContent {
//   color: #ffff00;
// }

// When the prompter is not in edit mode, it will be readonly (playing or paused mode).
// When not editing, we want to hide an edit type UI elements like the delete buttons or insert
// buttons.
.PrompterContent.ReadOnly {

  .HideWhenPrompting {
    visibility: hidden;
    pointer-events: none;
  }

  .SegmentElementHeader {
    // Hide delete button, etc
  }

  .SegmentElementFooter {
    // Hide footer text stats
  }

  .SegmentInsertToolbar {
    .ToolbarContents {
      visibility: hidden;
      pointer-events: none;
    }
  }
}
