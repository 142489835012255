.Navbar {
  transition: transform 500ms ease-in-out;
  transform: none;
  pointer-events: all;
}

.Navbar.hideMode {
  transform: translateY(-50%);
}

@media (min-width: 600px) {
  .Navbar.hideMode {
    transform: translateY(-125%);
  }
}