
.VirtualCursor {
  position: absolute;

  .CursorCaret {
    width: 2px;
    height: 100%;
    background: #ffff00;

    animation: cursor-blink 1.0s infinite;
  }

  .CursorLabel {
    position: absolute;
    bottom: 100%;
    margin-bottom: 3px;
    padding: 0.1em 0.5em;
    background: #ff0000;
    color: #fff;
    font-weight: 550;

    user-select: none;
    pointer-events: none;

    border-radius: 6px 6px 6px 0;
  }

  &.FlipHorizontal {
    transform: rotateY(180deg);
  }
  &.FlipVertical {
    transform: rotateX(180deg);
  }
  &.FlipHorizontal.FlipVertical {
    transform: rotateY(180deg) rotateX(180deg);
  }
}

@keyframes cursor-blink {
  0%, 40% {
    opacity: 1;
  }
  50%, 90% {
    opacity: 0;
  }
}
