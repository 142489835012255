/*
 * .BRollElement is the outer wrapper for an broll node in the Slate editor.
 */
.BRollElement {
  margin: 0.125em 0;
  background: rgb(19, 19, 19);
  color: rgb(78, 78, 78);
  font-size: 0.5em;
  line-height: 1em;
  border: 3px solid rgb(19, 19, 19);
  border-radius: 8px;

  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */

  user-select: none;
  cursor: pointer;

  /*
   * These rules apply only when the image node is selected/focused in the Slate editor.
   */
	&.isSelected {
    border: 3px solid #b4d5ff;
	}

	&.hidden {
    opacity: 0;
	}

  h1, h2, h3, h4 {
    width: 100%;
    margin:0;padding:10px 25px;
    font-size: 0.5em;
    border-radius: 8px;

    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;  
  }
 }

// This is the SM breakpoint in MUI
@media (min-width: 600px) {
  .BRollElement {
    border-radius: 16px;

    h1, h2, h3, h4 {
      border-radius: 16px;
    }    
  }
}