.PeerDeviceButton {

  // This is the container for both primary and secondary rows of text on the peer device button
  .MuiListItemText-root {
    padding-right: 1.5em;
  }

  // This is the primary text row on the peer device button
  /*
  .MuiListItemText-primary {
    //
  }
  */

  // This is the secondary text row on the peer device button
  .MuiListItemText-secondary {
    font-size: 1.0rem;

    sup {
      padding-left: 0.15em;
      color: rgba(0, 0, 0, 0.6);
      vertical-align: top;
    }
  }

}