.SegmentInsertToolbar {
  padding: 4px 0;
  font-size: 0.5em;    
  line-height: 1;
  user-select: none;

  transition: color 250ms ease-in-out;

  .ToolbarContents { 
    /* to hide the lines from behind the text, you have to set the background color the same as the container */ 
    // background: #000;
    color: #4E4E4E;
    // width: fit-content;
    display: flex;
    // margin: 0 auto;
    padding: 0 8px;
    // font-size: 0.5em;
    // font-size: 1em;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      color: #fff;
    }

    &:before, &:after {
      content:"";
      flex: 1;
      color: #fff;
      border-top: 2px solid transparent;
      transition: border-top 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      margin: 0 3px;
      transform: translateY(50%);
      width: 98%;
      pointer-events: none;
    }

    &:hover:before, &:hover:after {
      border-top: 2px solid #4E4E4E;
    }
  }
}