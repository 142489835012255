// KbdElement.scss

.PrompterContentContainer {
  .kbdshortcut {
    display: inline-flex;
    vertical-align: text-bottom;
    align-items: center;
    color: #eee;
    font-size: 0.5em;
    user-select: none;
  }
  kbd {
    position: relative;
    display: inline-block;
    min-width: 2em;
    min-height: 2em;
    margin: 0px 4px;
    padding: 8px 22px 8px 15px;
    border-radius: 10px;
    color: #fff;
    background: linear-gradient(180deg, #282828, #202020);
    border: 1px solid #282828;
    box-shadow: inset -8px 0 8px rgba(0,0,0,0.15),
    inset 0 -8px 8px rgba(0,0,0,0.25),
    0 0 0 2px rgba(0,0,0,0.75), 10px 20px 25px rgba(0,0,0,0.4);
    overflow: hidden;
    line-height: 1em;  
    font-size: 1em;
    text-transform: uppercase;
    z-index:0;
  }
  kbd::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 4px;
    bottom: 14px;
    right: 12px;
    background: linear-gradient(90deg, #232323, #4a4a4a);
    border-radius: 10px;
    box-shadow: -10px -10px 10px rgba(255,255,255,0.25), 10px 5px 10px rgba(0,0,0,0.15);
    border-left: 1px solid #0004;
    border-bottom: 1px solid #0004;
    border-top: 1px solid #0009;
    z-index:-1
  }  
}