.PlanFeatures {
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.875rem;
  line-height: 1.5;

  ul.ListIncluded {
    margin: 0;
    padding-left: 24px;
    list-style: none;

    li {
      position: relative;
      padding: 0 0 0.75em 0;
    }
    li::before {
      /* Convert SVG to CSS background: https://yoksel.github.io/url-encoder/ */
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z' fill='%231A7F37'%3E%3C/path%3E%3C/svg%3E");
      color: #1A7F37;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 3px;
      left: -20px;
    }
    li.awesome::before {
      /* Convert SVG to CSS background: https://yoksel.github.io/url-encoder/ */
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='m19 9 1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25L19 9zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12l-5.5-2.5zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25L19 15z' fill='%231A7F37'%3E%3C/path%3E%3C/svg%3E");
    }
  }
  ul.ListNotIncluded {
    margin: 0.75em 0;
    padding-left: 24px;
    list-style: none;

    li {
      position: relative;
      padding: 0 0 0.75em 0;
    }
    li::before {
      /* Convert SVG to CSS background: https://yoksel.github.io/url-encoder/ */
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' fill='%23CF222E'%3E%3C/path%3E%3C/svg%3E");
      color: #CF222E;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 3px;
      left: -20px;
    }
  }
}