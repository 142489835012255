// TourStyles.scss
.shepherd-button {
	// background: #3288e6;   // Original Value
  background-color: #1976d2;
  color: #fff;
	border: 0;
	border-radius: 3px;
	// color: hsla(0,0%,100%,.75);  // Original Value
	cursor: pointer;
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: uppercase;
	margin-right: .5rem;
	padding: .5rem 1.5rem;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
	// transition: all .5s ease;  // Original Value
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	&:not(:disabled) {
		&:hover {
			// color: hsla(0,0%,100%,.75);  // Original Value
      background-color: #1565c0;
      box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
		}
	}
	&:disabled {
		cursor: not-allowed;
	}
}
.shepherd-button.shepherd-button-secondary {
	background: transparent;
	// color: rgba(0,0,0,.75);  // Original Value
  color: #1976d2;
  box-shadow: none;
	&:not(:disabled) {
		&:hover {
			// background: #d6d9db; // Original Value
      background-color: rgba(25, 118, 210, 0.04);
      box-shadow: none;
		}
	}
}
.shepherd-footer {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0 .75rem .75rem;
	.shepherd-button {
		&:last-child {
			margin-right: 0;
		}
	}
}
.shepherd-progress {
	padding-left: 0.5rem;
	flex: 1;
  color: #ccc;
}
.shepherd-cancel-icon {
	background: transparent;   // Original Value
  border-radius: 50%;
	border: none;
	// color: hsla(0,0%,50%,.75); // Original Value
  color: #9e9e9e;
	cursor: pointer;
	font-size: 2em;
	font-weight: 400;
  line-height: 1em;
  width: 1em;
	margin: 0;
	padding: 0;
  // transition: color .5s ease;  // Original Value
	transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	&:hover {
    background-color: rgba(0, 0, 0, 0.04);
		// color: rgba(0,0,0,.75);    // Original Value
	}
}
// .shepherd-has-title {
// 	.shepherd-content {
// 		// .shepherd-cancel-icon {
// 		// 	color: hsla(0,0%,50%,.75);
// 		// 	&:hover {
// 		// 		color: rgba(0,0,0,.75);
// 		// 	}
// 		// }
// 		.shepherd-header {
// 			// background: #e6e6e6;   // Original Value
// 			padding: 1em;
// 		}
// 	}
// }
.shepherd-title {
	color: rgba(0,0,0,.75);
	display: flex;
	flex: 1 0 auto;
	// font-size: 1rem;   // Original Value
	// font-weight: 400;  // Original Value
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
	line-height: 40px;
	margin: 0;
	padding: 0;

	&::before {
		content: '';
		width: 40px;
    height: 40px;
		// background: url(https://lh3.googleusercontent.com/a-/ACB-R5SyNE-ttheUZlZW2277g3FC_SAqsbIwz9pfzjsE=s80-p);
		background: url(./avatar-ben.jpg);
    background-size: contain;
		border-radius: 50%;
		margin-right: 12px;
	}
}
.shepherd-header {
	align-items: center;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	display: flex;
	justify-content: flex-end;
	line-height: 2em;
	// padding: .75rem .75rem 0;	// Original Value
	padding: 12px 12px 0;
}
.shepherd-text {
	color: rgba(0,0,0,.75);
  // border-top: 1px solid rgba(0, 0, 0, 0.12);
	font-size: 1rem;
	line-height: 1.3em;
	// padding: .75em; // Original Value
	padding: 16px 12px;
	p {
		margin-top: 0;
    margin-bottom: 1.5em;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.shepherd-content {
	border-radius: 5px;
	outline: none;
	padding: 0;

	.MuiSvgIcon-root {
		user-select: none;
		width: 1em;
		height: 1em;
		display: inline-block;
		fill: currentcolor;
		vertical-align: middle;
	}
}
.shepherd-element {
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 1px 4px rgba(0,0,0,.2);
	max-width: 400px;
	opacity: 0;
	outline: none;
	transition: opacity .3s,visibility .3s;
	visibility: hidden;
	width: 100%;
	z-index: 9999;
	box-sizing: border-box;
	* {
		box-sizing: border-box;
	}
	&:after {
		box-sizing: border-box;
	}
	&:before {
		box-sizing: border-box;
	}
}
.shepherd-enabled.shepherd-element {
	opacity: 1;
	visibility: visible;
}
.shepherd-element[data-popper-reference-hidden] {
	&:not(.shepherd-centered) {
		opacity: 0;
		pointer-events: none;
		visibility: hidden;
	}
}
.shepherd-arrow {
	height: 16px;
	position: absolute;
	width: 16px;
	z-index: -1;
	&:before {
		height: 16px;
		position: absolute;
		width: 16px;
		z-index: -1;
		background: #fff;
		content: "";
		transform: rotate(45deg);
	}
}
.shepherd-element[data-popper-placement^=top] {
	>.shepherd-arrow {
		bottom: -8px;
	}
}
.shepherd-element[data-popper-placement^=bottom] {
	>.shepherd-arrow {
		top: -8px;
	}
}
.shepherd-element[data-popper-placement^=left] {
	>.shepherd-arrow {
		right: -8px;
	}
}
.shepherd-element[data-popper-placement^=right] {
	>.shepherd-arrow {
		left: -8px;
	}
}
.shepherd-element.shepherd-centered {
	>.shepherd-arrow {
		opacity: 0;
	}
}
// Seems like a bug??
// .shepherd-element.shepherd-has-title[data-popper-placement^=bottom] {
// 	>.shepherd-arrow {
// 		&:before {
// 			background-color: #e6e6e6;
// 		}
// 	}
// }
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target {
	pointer-events: none;
	* {
		pointer-events: none;
	}
}
.shepherd-modal-overlay-container {
	height: 0;
	left: 0;
	opacity: 0;
	overflow: hidden;
	pointer-events: none;
	position: fixed;
	top: 0;
	transition: all .3s ease-out,height 0ms .3s,opacity .3s 0ms;
	width: 100vw;
	z-index: 9997;
}
.shepherd-modal-overlay-container.shepherd-modal-is-visible {
	height: 100vh;
	opacity: .5;
	transform: translateZ(0);
	transition: all .3s ease-out,height 0s 0s,opacity .3s 0s;
	path {
		pointer-events: all;
	}
}