.WordLimitNoticeContainer {
  position: absolute;
  left: 0;
  pointer-events: none;
  z-index: var(--zindex-wordlimit-notice);

  // padding: 0 4px;
  container-type: inline-size;
}
.WordLimitNotice {
  position: sticky;
  left: 0;
  // background: rgba(38, 38, 38, 0.5);
  background: rgba(28, 28, 28, 0.75);
  border-radius: 8px;
  outline: 2px solid #4297d7;
  box-shadow: 0 0 12px #4297d7;

  color: #fff;
  // font-size: 0.5em;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,outline 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  user-select: none;
  pointer-events: all;

  .NoticeTitle {
    padding: 0 0.5rem;
  }
  .ExistingSignin {
    height: 6rem;
    padding: 0 0.5rem;
  }

  &.isAtEndOfScript {
    color: #4E4E4E;
    outline: none;
    box-shadow: none;
  }
}
@media (min-width: 600px) {
  .WordLimitNotice {
    border-radius: 16px;
  }
}
.FlipHorizontal {
  .WordLimitNotice {
    transform: rotateY(180deg);
  }
}
.FlipVertical {
  .WordLimitNotice {
    transform: rotateX(180deg);
  }
}
.FlipHorizontal.FlipVertical {
  .WordLimitNotice {
    transform: rotateY(180deg) rotateX(180deg);
  }
}

.WordLimitNoticeButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  // justify-content: space-evenly;
  gap: 0.5rem;
  padding: 0 1rem;
}

// This is the SM breakpoint in MUI
@container (min-width: 768px) {
  .WordLimitNoticeButtonContainer {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 0 0.5rem;
  }
}
