/**
 * Many UI Components may be shown on top of another component in the user interface. This is
 * referred to as the "z-order" of the elements also known as stacking order.
 *
 * By default components that are rendered later in the DOM will apear on top of components
 * rendered earlier in the DOM.
 *
 * However there are some cases where a component found earlier in the DOM should appear above a
 * component found later in the DOM.
 *
 * For example the top and bottom "focus guides" should appear on top of all prompter content -
 * except for the WordLimitNotice component and the EndOfScript component which should appear on
 * top of the focus guides in order to maximize user awareness.
 */
 body {
  // Script selection background color..
  --editor-selection-background: rgba(0, 116, 255, 0.8);

  // This is the left side panel which contains quick access buttons for touch navigation.
  --zindex-prompter-hud-left: 1150;

  // This is the right side panel which contains prompter stats such as word count as well as the
  // script table of contents widget.
  --zindex-prompter-hud-right: 1150;

  // We want our word limit notice component to show above the focus guides.
  --zindex-wordlimit-notice: 1080;

  // When the prompter reaches the end of the script content (the end element has entered or passed
  // the cue position), we will elevate the end element zindex above the focus guides by adding
  // `isEndOfScript` class to `.EndElementOffScriptContainer`.
  --zindex-end-element: 1105;

  // The prompter "focus guide" is composed of two elements, mask-top and mask-bottom, which are
  // semi-transparent rectangles that darken some portion of the top and bottom of the viewport
  // (focusing your attention near the cue position).
  --zindex-prompter-mask: 1001;

  // The cue position is marked with an arrow icon to draw the user's eyes. The cue position
  // indicator should appear above all prompter content.
  --zindex-prompter-cue-indicator: 1102;

  // The prompter viewport UI should be on top of the prompter masks and cue indicator.
  // This component will contain the app nav bar, control bar, left and right panels and will
  // resize to the available viewport (not on top of the open media panel, if open).
  --zindex-prompter-viewport-ui: 1102;

  // The editor toolbar hovers over the prompter content while the user is in edit mode to make
  // changes to the prompter script content. The editor toolbar should appear above all other
  // content including the word limit notice or end of script notice.
  --zindex-prompter-editor-toolbar: 1103;

  // When the MediaPanel is being displayed on the top, right, bottom, or left of the viewport
  // (not the background) we want to raise it above the prompter content and also prompter UI.
  --zindex-mediapanel-above-content: 1150;

  // The development site notice is shown on the dev site so we don't mistake dev for prod.
  // This element should be above all UI elements except for modal dialogs and the app menu.
  --zindex-development-site-notice: 1185;

  // When a user is drag-dropping a file onto FluidPrompter, we want the drag drop indicator to
  // appear above all content.
  --zindex-promptercontainer-dragactive: 1500;
}

.ForceScrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.ForceScrollbar::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.26);
  border-radius: 5px;
}
.ForceScrollbar::-webkit-scrollbar-track {
  background: #f9f9f9;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}