.HoveringToolbar {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);

  width: 100%;

  color: #fff;
  transition: opacity 0.35s;
  z-index: var(--zindex-prompter-editor-toolbar);
  font-size: 1rem;
  overflow-x: scroll;
  touch-action: pan-x;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.HoveringToolbar::-webkit-scrollbar {
  display: none;
}

// This is the SM breakpoint in MUI
@media (min-width: 600px) {
  .HoveringToolbar {
    // min-width: 300px;
    width: unset;
  }
}
