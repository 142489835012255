.PrompterHudLeft {
  position: absolute;
  top:0;bottom:0;
  left:0;
  width: 22vw;
  padding: 0px 0px 0px 8px;

  /*background: #ff0000;*/
  color: #fff;

  z-index: var(--zindex-prompter-hud-left);
  pointer-events: none;

  p {
    text-align: left;
  }
}

.PrompterHudLeft.hideMode {
  transform: translateX(-100%);
}

// This md breakpoint in MUI is 900px
@media (min-width: 900px) {
  .PrompterHudLeft {
    width: 20vw;
    padding: 8px 8px 8px 16px;
  }
}

@media (min-width: 1280px) {
  .PrompterHudLeft {
    width: 14vw;
    padding: 8px 8px 8px 16px;
  }
}

// Media query for higher resolution (probably 4k, but sometime laptops have odd resoutions) displays
@media only screen and (min-width: 2000px) {
  .PrompterHudLeft {
    width: 10vw;
    // padding: 8px 8px 8px 16px;
  }
}

$ripple-color: #fff;

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0rem rgba($ripple-color, 0.05),
                0 0 0 1rem rgba($ripple-color, 0.05),
                0 0 0 2rem rgba($ripple-color, 0.05),
                0 0 0 5rem rgba($ripple-color, 0.05);
  }
  100% {
    box-shadow: 0 0 0 1rem rgba($ripple-color, 0.05),
                0 0 0 2rem rgba($ripple-color, 0.05),
                0 0 0 5rem rgba($ripple-color, 0.05),
                0 0 0 8rem rgba($ripple-color, 0);
  }
}