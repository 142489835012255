.PrompterViewportUIContainer {
  position: fixed;
  z-index: var(--zindex-prompter-viewport-ui);
  pointer-events: none;

  display: grid;
  grid-template-columns: 22vw 1fr 25vw;
  grid-template-rows: min-content 64px 1fr 64px;
  gap: 0px 0px;
  grid-template-areas:
    "NoticeBar NoticeBar NoticeBar"
    "Navbar Navbar Navbar"
    "LeftHud . RightHud"
    "ControlBar ControlBar ControlBar";

  .NoticeBarContainer {
    grid-area: NoticeBar;
    position: relative;

    // background: #ff0000;
  }
  .NavbarContainer {
    grid-area: Navbar;
    position: relative;

    // background: #00ff00
  }
  .LeftHUDContainer {
    grid-area: LeftHud;
    position: relative;
    // background: #0000ff;
  }
  .RightHUDContainer {
    grid-area: RightHud;
    position: relative;
    // background: #00ffff;
  }
  .ControlBarContainer {
    grid-area: ControlBar;
    position: relative;
    // background: #ffff00;
  }

}

// This md breakpoint in MUI is 900px
@media (min-width: 900px) {
  .PrompterViewportUIContainer {
    grid-template-columns: 20vw 1fr 22vw;
  }
}

@media (min-width: 1280px) {
  .PrompterViewportUIContainer {
    grid-template-columns: 14vw 1fr 18vw;
  }
}

// Media query for higher resolution (probably 4k, but sometime laptops have odd resoutions) displays
@media only screen and (min-width: 2000px) {
  .PrompterViewportUIContainer {
    grid-template-columns: 10vw 1fr 18vw;
  }
}
