.StartElement {
  user-select: none;

  position: relative;
  // margin-bottom: 25vh;

  color: #4E4E4E;
  border-radius: 8px;
  transition: background-color 100ms linear, color 100ms linear, border 100ms linear, box-shadow 100ms linear, margin 500ms ease-in-out;

  display: flex;
  flex-direction: column;
  justify-content: center; /* align horizontal */
  // align-items: center; /* align vertical */

  .StartElementContent {
    border-radius: 8px;
    transform: translateY(-50%);

    &:focus {
      outline: none;
    }

    &.isSelected {
      outline: 2px dashed #ccc;
    }
  }
}

.StartElementHeader {
  position: relative;
  display: flex;
  background: rgba(38, 38, 38, 0.5);
  border-radius: 8px 8px 0 0;

  h1 {
    width: 100%;
    margin:0;
    padding:16px 25px 0 25px;
    font-size: max(18px, 0.5em);
    text-transform: uppercase;
    // border-radius: 8px 8px 0 0;
  }
}
.StartElementTitle {
  margin:0;padding:0 16px;
  font-size: 2rem;
  font-style: italic;

  /*&:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }*/
}

.StartElementActionsInline {
  user-select: none;

  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: stretch;  // space-between

  padding-top:16px;
  background: rgba(38, 38, 38, 0.5);
  color: #4E4E4E;
  // border: 2px solid transparent;	//rgba(255, 255, 255, 0.075);
  // border-top-color: rgba(0,0,0,0.125);
  border-radius: 0 0 8px 8px;

  font-size: 1rem;

  .ShotLogButton {
    flex-direction: column;
    flex: 1;
    border-radius: 8px;

    .MuiButton-startIcon {
      margin-right: 0;

      .MuiSvgIcon-root {
        font-size: 1em;
      }
    }
    .MuiButton-endIcon {
      margin-left: 0;

      .MuiSvgIcon-root {
        font-size: 1em;
      }
    }

    .ShotLogButtonProgress {
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      opacity: 0.3;
    }
    .ShotLogButtonLabel {
      padding: 6px 8px;
      font-size: 1em;
    }
  }

  .ShotLogButton.FlexReverse {
    flex-direction: column-reverse;
  }
}

.StartElementActionsCollapse {
  border-top: 2px solid rgba(0,0,0,0.125);	//rgba(255, 255, 255, 0.075);
  // border-top-color: rgba(0,0,0,0.125);
}

.StartElementQuickActions {
  position: absolute;
  bottom: 100%;
  padding-bottom: 8px;

  font-size: 0.25em;
  // transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.PrompterPaused .StartElementQuickActions, .PrompterPlaying .StartElementQuickActions {
  visibility: hidden;
  pointer-events: none;
}

// This is the SM breakpoint in MUI
@media (min-width: 600px) {
  .StartElement {
    border-radius: 16px;
  }
  .StartElementHeader {
    border-radius: 16px 16px 0 0;
    h1 {
      text-align: center;
      // border-radius: 16px 16px 0 0;
    }
  }
  .StartElementActionsInline {
    font-size: 1.3rem;
    border-radius: 0 0 16px 16px;

    .ShotLogButton {
      border-radius: 16px;
    }
  }
}