.#{$rt-namespace}__toast {
  position: relative;
  width: 312px;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
  &--rtl {
    direction: rtl;
  }
  &-body {
    margin: auto 0;
    flex: 1 1 auto;
    padding: 2px 6px;
    display: flex;
    align-items: center;
    user-select: none;
    & > div:last-child {
      flex: 1;
    }
  }
  &-icon {
    margin-inline-end: 12px;
    min-width: 20px;
    max-width: 40px;
    flex-shrink: 0;
    display: flex;
  }
  &.connecting {
    width:unset;
  }
}

.#{$rt-namespace}--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.#{$rt-namespace}--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media #{$rt-mobile} {
  .#{$rt-namespace}__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}

.f {
  --y: calc(var(--len) - var(--nth));
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  transform: translate3d(0, calc(var(--y) * -40%), 0)
    scale(calc(1 - 0.05 * var(--y)));
  transition: all 0.3s;
  min-height: 80px;
}
