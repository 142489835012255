.SegmentSubscribeContainer {
  padding-bottom: 25vh;
}
.SegmentElement.SegmentSubscribe {
  background: rgba(28, 28, 28, 0.75); //#000;

  .SegmentElementFooter {
    border-color: #ffff00;
    display: flex;
    justify-content: right;
    padding: 8px 8px;

    span {
      font-size: 1rem;
    }
  }
}

// This is the SM breakpoint in MUI
@media (min-width: 600px) {
  .SegmentSubscribeContainer {
    padding-bottom: 20vh;
  }
  .SegmentElement.SegmentSubscribe {
    .SegmentElementFooter {
      padding: 8px 16px;

      span {
        font-size: 1.5rem;
      }
    }
  }
}