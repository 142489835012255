.NoticeBarContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;

  transition: max-height 350ms ease-in-out;
}

.NoticeBar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;

  /* Using color shades generator, with 11 steps from pure yellow. https://mdigi.tools/color-shades/#ffff00 */
  background: #171700;
  border-color: #464600;
  border-style: solid;
  border-width: 1px 0;
  color: #ededed;

  pointer-events: all;
  z-index: 1150;

  @media (min-width: 768px) {
    padding: 8px 8px 8px 12px;
  }
}

.NoticeButton {

  .Cta {
    display: none;
  }
  @media (min-width: 768px) {
    margin-left: 16px;

    .ShortCta {
      display: none;
    }
    .Cta {
      display: unset;
    }
  }
}

.Shake {
  animation: Shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes Shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}