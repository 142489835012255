.PrompterContent {
  position: relative;
  // margin: 0 auto; /* Margin 0 auto will center the content when the width is less than 100% */
  padding: 0 1em;
  transition: transform 250ms linear 0s; /* only transition top property */
  // will-change: transform;
  will-change: scroll-position, transform, opacity !important;

  :not(pre)>code {
    background-color: rgba(255, 255, 255, 0.16);
    border-radius: 4px;
    padding: 2px 4px;
    font-size: 0.8125em;
  }

  ::selection, .EditorSelection{
    color: inherit;
    background-color: var(--editor-selection-background);
  }

  .CursorLocator {
    height: 1em;
  }

  &.FlipHorizontal {
    transform: rotateY(180deg);
  }
  &.FlipVertical {
    transform: rotateX(180deg);
  }
  &.FlipHorizontal.FlipVertical {
    transform: rotateY(180deg) rotateX(180deg);
  }
}

.EmptyLinePlaceholder {
  opacity: 0.3;
  position: absolute;
  color: rgb(255, 255, 255);
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  left: 0.25em;
  right: 0;
  font-size: 0.5em;
  line-height: 3em;
  user-select: none;
  pointer-events: none;
}

.ReadOnly {
  .ContentEditor {
    user-select: none;
  }
}