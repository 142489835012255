.EndElementActionsInline {
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: rgba(38, 38, 38, 0.5);
  color: #4E4E4E;
  border: 2px solid rgba(0,0,0,0.125);	//rgba(255, 255, 255, 0.075);
  border-radius: 0 0 8px 8px;
  border-width: 2px 0 0 0;

  font-size: 1rem;

  .ShotLogButton {
    flex-direction: column;
    flex: 1;
    border-radius: 8px;

    .MuiButton-startIcon {
      margin-right: 0;

      .MuiSvgIcon-root {
        font-size: 1em;
      }
    }
    .MuiButton-endIcon {
      margin-left: 0;

      .MuiSvgIcon-root {
        font-size: 1em;
      }
    }

    .ShotLogButtonProgress {
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      opacity: 0.3;
    }
    .ShotLogButtonLabel {
      padding: 6px 8px;
      font-size: 1em;
    }
  }

  .ShotLogButton.FlexReverse {
    flex-direction: column-reverse;
  }
}

// This is the SM breakpoint in MUI
@media (min-width: 600px) {
  .EndElement {
    border-radius: 16px;

    h1 {
      width: 100%;
      margin:0;padding:10px 25px;
      font-size: 0.5em;
      text-align: center;
      border-radius: 16px 16px 0 0;
    }
  }
  .EndElementActionsInline {
    font-size: 1.3rem;
    border-radius: 0 0 16px 16px;

    .ShotLogButton {
      border-radius: 16px;
    }
  }
}
