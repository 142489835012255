// ShotLogModal.scss
.ShotLogModal {
  width: 100%;
  color: #4297d7;
  border: 2px solid #4297d7;
  border-radius: 8px;
  box-shadow: 0 0 12px #4297d7;
  outline: none;
}

.ShotLogActions {
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #131313;

  /*
  color: #4E4E4E;
  border: 2px solid #131313;	//rgba(255, 255, 255, 0.075);
  */
  border-radius: 0 0 8px 8px;

  font-size: 4vw;
  z-index: 1100;

  .ShotLogButton {
    flex-direction: column;
    width: 33%;
    border-radius: 8px;

    .MuiButton-startIcon {
      margin-right: 0;

      .MuiSvgIcon-root {
        font-size: 2em;
      }
    }
    .MuiButton-endIcon {
      margin-left: 0;

      .MuiSvgIcon-root {
        font-size: 2em;
      }
    }

    .ShotLogButtonProgress {
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      opacity: 0.3;
    }
    .ShotLogButtonLabel {
      padding: 6px 8px;
      font-size: 1em;
    }
  }

  .ShotLogButton.FlexReverse {
    flex-direction: column-reverse;
  }
}

// This is the SM breakpoint in MUI
@media (min-width: 600px) {
  .ShotLogModal {
    width: 80%;
    border-radius: 16px;
  }

  .ShotLogActions {
    font-size: 2em;
    border-radius: 0 0 8px 8px;
    padding: 6px 2px 2px 2px;

    .ShotLogButton {
      flex-direction: row;
      border-radius: 8px;

      .MuiButton-startIcon {
        margin-right: 8px;

        .MuiSvgIcon-root {
          font-size: 1em;
        }
      }
      .MuiButton-endIcon {
        margin-left: 8px;

        .MuiSvgIcon-root {
          font-size: 1em;
        }
      }
    }

    .ShotLogButton.FlexReverse {
      flex-direction: row;
    }
  }
}

// Media query for higher resolution (probably 4k, but sometime laptops have odd resoutions) displays
@media only screen and (min-width: 2000px) {
  .ShotLogModal {
    width: 60%;
  }
}