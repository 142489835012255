.PauseElement {
  user-select: none;

	position: relative;

  transition: background-color 100ms linear, color 100ms linear, border 100ms linear, box-shadow 100ms linear;

	// display: flex;
	// align-items: center;
	transition: 'color 0.25s';

	::selection {
		background: transparent;
		color: #4E4E4E;
	}

	&:focus {
    outline: none;
	}

	.PauseElementHeader {
    display: flex;
		align-items: center;

		background: rgba(38, 38, 38, 0.5);	//#131313;
		color: #4E4E4E;
		border-radius: 8px;

		.Content {
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;	// On narrow mobile screens with larger font size, the word "PAUSED" may not fit on screen.

			margin: 0;
			padding: 10px 8px 10px 92px;
			font-size: 0.5em;
			text-align: center;
			text-transform: uppercase;
			flex-grow: 1;
		}
	}

	&.isSelected {
		.PauseElementHeader {
			outline: 2px dashed #ccc;
		}
	}

	&.isPaused {
		.PauseElementHeader {
			background: rgba(28, 28, 28, 0.75);
			color: #4297d7;
			outline: 2px solid #4297d7;
			box-shadow: 0 0 12px #4297d7;
		}
	}

	h1, h2, h3 {
		width: 100%;
		margin:0;padding:10px 25px;
		font-size: 0.5em;
    text-align: center;
		text-transform: uppercase;
		flex-grow: 1;
	}
}

// This is the SM breakpoint in MUI
@media (min-width: 600px) {
  .PauseElement {
		.PauseElementHeader {
    	border-radius: 16px;
		}

    h1 {
      text-align: center;
      border-radius: 16px;
    }
  }
}