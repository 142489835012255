.HorizontalLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -1em;
  opacity: 1.0;
  transition: opacity 250ms ease-in-out;
  z-index: 1101;

  .DividerLine {
    width: 100%;
    display: flex;
    font-size: 0.25em;

    span {
      background: rgb(255, 0, 0);
      border-radius: 4px;
      margin: 0 3px;
      padding: 0 4px;

      text-transform: uppercase;
      user-select: none;
    }
    
    &:before, &:after {
      flex: 1;
      color: #fff;
      width: 100%;
      pointer-events: none;
      border-top: 3px dashed #ff0000;
    }
    &:before {
      content:"";
    }
  }
  .DividerLine.HasCaption {
    &:before, &:after {
      transform: translateY(45%);
    }
    &:after {
      content:"";
    }
  }

  .LeftMarker, .RightMarker {
    width: 1em;
    height: 0.5em;
    position: relative;

    span {
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      font-size: 1rem;
      line-height: 0.5em;
      z-index: 1;
      text-align: center;
    }
  }
  .LeftMarker {
    span {
      right: 0.25em;
    }
  }
  .RightMarker {
    span {
      left: 0.25em;
    }
  }
  .LeftMarker:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0.24em;
    top: 0;
    bottom: 0;
    background: red;
  }
  .LeftMarker:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    border-left: 0.25em solid red;
    border-top: 0.25em solid transparent;
    border-bottom: 0.25em solid transparent;
  }

  .RightMarker:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    border-right: 0.25em solid red;
    border-top: 0.25em solid transparent;
    border-bottom: 0.25em solid transparent;
  }
  .RightMarker:after {
    content: "";
    position: absolute;
    left: 0.24em;
    right: 0;
    top: 0;
    bottom: 0;
    background: red;
  }
}

.PrompterPlaying {
  .HorizontalLine {
    opacity: 0.4;

    span {
      display: none;
    }
  }
}